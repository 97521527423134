"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupArrayBy = exports.formatDate = exports.milliseconds2Date = exports.date2Milliseconds = exports.filterTableBySearchValue = exports.sortTable = exports.load = exports.addCustomEventPolyfill = exports.findLocale = exports.loadClosestPolyfill = exports.isElementInViewport = exports.loadScript = exports.loadStyle = void 0;
function loadStyle(href, media, id, onLoadCallback) {
    if (media === void 0) { media = 'all'; }
    if (id && document.querySelector("#".concat(id)))
        return;
    var style = document.createElement('link');
    style.rel = 'stylesheet',
        style.href = href;
    style.media = media;
    if (id)
        style.id = id;
    document.getElementsByTagName('head')[0].appendChild(style);
    style.addEventListener('load', function () {
        if (onLoadCallback)
            onLoadCallback();
    });
}
exports.loadStyle = loadStyle;
/**
 * Load a JavaScript file
 *
 * The callback stack is used to collect all callback functions
 * and call it at the same time to avoid callbacks too early.
 * Example: Creating three PoiMap Objects on the same page. Every Object tries to load Leaflet
 * but because of the used ID Leaflet is included only once (multiple Leaflet scripts would not make sense).
 * But every PoiMap needs to know when Leaflet is ready, therefore all callbacks are collected and called after
 * the load event of the script.
 *
 * @param src Path to script
 * @param id (Optional) ID for the script to prevent multiple scripts.
 * @param onLoadCallback (Optional) Required "id" parameter. Callack when script is loaded.
 */
function loadScript(src, id, onLoadCallback, type) {
    if (!window.OebbScripts) {
        /**
         * Script loading global object with "OebbScripts" namespace.
         */
        window.OebbScripts = {
            /**
             * Script loading callback Stack.
             * Object with "id" and "callback".
             */
            scriptCallbackStack: [],
            /**
             * Check if callback stack has been executed.
             * If the script is already on the page and a class need it later
             * the callback should be executed therefore we need to know if the
             * callbacks from the stack have been executed.
             */
            executedCallbacksById: [],
        };
    }
    var oebbScripts = window.OebbScripts;
    if (id && document.querySelector("#".concat(id))) {
        if (onLoadCallback && oebbScripts.executedCallbacksById.indexOf(id) == -1) {
            oebbScripts.scriptCallbackStack.push({
                id: id,
                callback: onLoadCallback
            });
        }
        else if (onLoadCallback) {
            if (oebbScripts.executedCallbacksById.indexOf(id) == -1)
                oebbScripts.executedCallbacksById.push(id);
            onLoadCallback();
        }
        return;
    }
    var script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    if (id)
        script.id = id;
    if (type && type == 'module')
        script.type = 'module';
    if (type && type == 'nomodule')
        script.noModule = true;
    if (id && onLoadCallback) {
        oebbScripts.scriptCallbackStack.push({
            id: id,
            callback: onLoadCallback
        });
    }
    script.addEventListener('load', function () {
        if (oebbScripts.scriptCallbackStack.length) {
            for (var _i = 0, _a = oebbScripts.scriptCallbackStack; _i < _a.length; _i++) {
                var callbackObject = _a[_i];
                if (callbackObject.id == this.id) {
                    callbackObject.callback();
                }
            }
            if (oebbScripts.executedCallbacksById.indexOf(id) == -1)
                oebbScripts.executedCallbacksById.push(id);
        }
        else {
            if (onLoadCallback)
                onLoadCallback();
        }
    });
    document.body.appendChild(script);
}
exports.loadScript = loadScript;
function isElementInViewport(el, ct) {
    var rectEl = el.getBoundingClientRect();
    var rectCt = ct.getBoundingClientRect();
    return (rectEl.top <= rectCt.top)
        ? (rectCt.top - rectEl.top) <= rectEl.height
        : (rectEl.bottom - rectCt.bottom <= rectEl.height);
}
exports.isElementInViewport = isElementInViewport;
function loadClosestPolyfill() {
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.msMatchesSelector ||
                Element.prototype.webkitMatchesSelector;
    }
    if (!Element.prototype.closest) {
        Element.prototype.closest = function (s) {
            var el = this;
            do {
                if (Element.prototype.matches.call(el, s))
                    return el;
                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType === 1);
            return null;
        };
    }
}
exports.loadClosestPolyfill = loadClosestPolyfill;
function findLocale(element) {
    loadClosestPolyfill();
    var closestElement = element.closest('[lang]');
    return closestElement ? closestElement.lang : 'de';
}
exports.findLocale = findLocale;
function addCustomEventPolyfill() {
    if (typeof window.CustomEvent === 'function')
        return false;
    function CustomEvent(event, params) {
        params = params || { bubbles: false, cancelable: false, detail: null };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }
    window.CustomEvent = CustomEvent;
}
exports.addCustomEventPolyfill = addCustomEventPolyfill;
function load(url, callback, callbackError, requestHeader) {
    if (requestHeader === void 0) { requestHeader = null; }
    sendRequest('GET', url, callback, callbackError, requestHeader);
}
exports.load = load;
function sendRequest(requestType, url, callback, callbackError, requestHeader) {
    if (requestHeader === void 0) { requestHeader = null; }
    var xhr = new XMLHttpRequest();
    xhr.open(requestType, encodeURI(url));
    if (requestHeader) {
        var objKey = Object.keys(requestHeader)[0];
        xhr.setRequestHeader(objKey, requestHeader[objKey]);
    }
    xhr.onerror = function (ev) {
        callbackError(ev);
    };
    xhr.onreadystatechange = ensureReadiness;
    function ensureReadiness() {
        if (xhr.readyState < 4) {
            return;
        }
        if (xhr.status !== 200) {
            callbackError(xhr);
            return;
        }
        // all is well
        if (xhr.readyState === 4) {
            callback(xhr);
        }
    }
    xhr.send(null);
}
function sortTable(n, table) {
    var rows, switching, i, x, y, xValue, yValue, shouldSwitch, dir, switchcount = 0;
    switching = true;
    dir = "asc";
    while (switching) {
        switching = false;
        rows = table.querySelectorAll("tbody tr");
        for (i = 0; i < (rows.length - 1); i++) {
            shouldSwitch = false;
            x = rows[i].querySelectorAll("td")[n];
            y = rows[i + 1].querySelectorAll("td")[n];
            xValue = x.innerHTML.toLowerCase();
            yValue = y.innerHTML.toLowerCase();
            if (x.dataset.cellValue) {
                xValue = x.dataset.cellValue;
            }
            if (y.dataset.cellValue) {
                yValue = y.dataset.cellValue;
            }
            if (dir == "asc") {
                if (xValue > yValue) {
                    shouldSwitch = true;
                    break;
                }
            }
            else if (dir == "desc") {
                if (xValue < yValue) {
                    shouldSwitch = true;
                    break;
                }
            }
        }
        if (shouldSwitch) {
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            switchcount++;
        }
        else {
            if (switchcount == 0 && dir == "asc") {
                dir = "desc";
                switching = true;
            }
        }
    }
    return dir;
}
exports.sortTable = sortTable;
function filterTableBySearchValue(searchInput, table) {
    var searchValue = searchInput.value.toUpperCase(), tableRows = table.querySelectorAll("tbody tr");
    for (var i = 0; i < tableRows.length; i++) {
        var tableRow = tableRows[i];
        if (tableRow.innerHTML.toUpperCase().indexOf(searchValue) > -1) {
            tableRow.style.display = "";
        }
        else {
            tableRow.style.display = "none";
        }
    }
}
exports.filterTableBySearchValue = filterTableBySearchValue;
function date2Milliseconds(dateString) {
    return new Date(dateString).getTime();
}
exports.date2Milliseconds = date2Milliseconds;
function milliseconds2Date(milliseconds) {
    return new Date(milliseconds).toLocaleString('de-DE', { month: "2-digit", day: "2-digit", year: "numeric" });
}
exports.milliseconds2Date = milliseconds2Date;
function formatDate(dateString) {
    return new Date(dateString).toLocaleString('de-DE', { month: "2-digit", day: "2-digit", year: "numeric" });
}
exports.formatDate = formatDate;
function groupArrayBy(groupingArray, key) {
    return groupingArray.reduce(function (r, a) {
        r[a[key]] = __spreadArray(__spreadArray([], r[a[key]] || [], true), [a], false);
        return r;
    }, {});
}
exports.groupArrayBy = groupArrayBy;
