"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.initStickyHeader = exports.initMenu = exports.Menu = void 0;
var Observer_1 = require("./Observer");
var Menu = /** @class */ (function (_super) {
    __extends(Menu, _super);
    function Menu() {
        var _this = _super.call(this) || this;
        _this.burgerLink = document.querySelector('.js-menu-burger');
        _this.html = document.querySelector('html');
        _this.menuOpen = false;
        _this.classNames = {
            menuOpen: 'menu-open',
        };
        _this.burgerLink.addEventListener('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            _this.toggleMobileMenu();
            _this.notifyObservers();
        });
        document.addEventListener('keyup', function (event) {
            if (event.key == 'Escape') {
                if (_this.menuOpen) {
                    _this.toggleMobileMenu();
                }
            }
        });
        return _this;
    }
    Menu.prototype.notify = function () {
        if (this.menuOpen) {
            this.toggleMobileMenu();
        }
    };
    Menu.prototype.toggleMobileMenu = function () {
        this.html.classList
            .toggle(this.classNames.menuOpen);
        this.menuOpen = !this.menuOpen;
    };
    return Menu;
}(Observer_1.Observer));
exports.Menu = Menu;
function initMenu() {
    if (document.querySelector('.main-menu')) {
        return new Menu();
    }
}
exports.initMenu = initMenu;
function initStickyHeader() {
    var html = document.querySelector('html');
    var header = document.querySelector('header');
    if (!header)
        return;
    var latestScrollY = 0;
    var ticking = false;
    window.addEventListener('scroll', function (e) {
        latestScrollY = document.documentElement.scrollTop;
        requestTick();
    });
    function requestTick() {
        if (!ticking) {
            requestAnimationFrame(stickHeader);
        }
        ticking = true;
    }
    function stickHeader() {
        ticking = false;
        if (latestScrollY >= 150) {
            html.classList.add('header-is-sticky');
            header.classList.add('is-sticky');
        }
        else {
            html.classList.remove('header-is-sticky');
            header.classList.remove('is-sticky');
        }
    }
}
exports.initStickyHeader = initStickyHeader;
