"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.domReady = void 0;
function domReady(fn) {
    function onReady(event) {
        document.removeEventListener('DOMContentLoaded', onReady);
        fn(event);
    }
    document.addEventListener && document.addEventListener('DOMContentLoaded', onReady);
}
exports.domReady = domReady;
