"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initToggleSublevelMobile = exports.ToggleSubmenuMobile = void 0;
var ToggleSubmenuMobile = /** @class */ (function () {
    function ToggleSubmenuMobile(nav) {
        var _this = this;
        this.handleArrowClick = function (event) {
            var arrowOpenClose = event.currentTarget;
            var subMenu = arrowOpenClose.nextElementSibling;
            var isOpen = subMenu.classList.contains('show-submenu');
            _this.closeAllSubmenus();
            if (!isOpen) {
                _this.toggleSubMenu(arrowOpenClose);
            }
        };
        this.nav = nav;
        this.init();
    }
    ToggleSubmenuMobile.prototype.closeAllSubmenus = function () {
        this.nav.querySelectorAll('.main-menu__main-item').forEach(function (item) {
            var subMenu = item.querySelector('.site-nav__menu-board');
            if (subMenu && subMenu.classList.contains('show-submenu')) {
                subMenu.classList.remove('show-submenu');
                subMenu.style.display = 'none';
                var arrowOpenClose = item.querySelector('.main-menu__main-item-expand');
                arrowOpenClose.style.transform = 'rotate(90deg)';
            }
        });
    };
    ToggleSubmenuMobile.prototype.toggleSubMenu = function (arrowOpenClose) {
        var subMenu = arrowOpenClose.nextElementSibling;
        subMenu.classList.toggle('show-submenu');
        if (subMenu.classList.contains('show-submenu')) {
            subMenu.style.display = 'block';
            arrowOpenClose.style.transition = 'transform 0.3s ease';
            arrowOpenClose.style.transform = 'rotate(270deg)';
        }
        else {
            subMenu.style.display = 'none';
            arrowOpenClose.style.transition = 'transform 0.3s ease';
            arrowOpenClose.style.transform = 'rotate(90deg)';
        }
    };
    ToggleSubmenuMobile.prototype.init = function () {
        var _this = this;
        this.nav.querySelectorAll('.main-menu__main-item-expand').forEach(function (button) {
            button.addEventListener('click', _this.handleArrowClick);
        });
    };
    return ToggleSubmenuMobile;
}());
exports.ToggleSubmenuMobile = ToggleSubmenuMobile;
function initToggleSublevelMobile() {
    if (document.querySelector('.site-nav__menu-board')) {
        return new ToggleSubmenuMobile(document.querySelector('.main-menu__list'));
    }
}
exports.initToggleSublevelMobile = initToggleSublevelMobile;
