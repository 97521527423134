"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDisclaimerTxt = exports.createDisclaimerBtn = exports.changeDisclaimerBtnText = exports.toggleYoutubeLocalstorage = exports.isYoutubeEnabled = void 0;
var I18n_1 = require("./I18n");
function isYoutubeEnabled() {
    return localStorage.getItem('oebb-yt-videos') ? true : false;
}
exports.isYoutubeEnabled = isYoutubeEnabled;
function toggleYoutubeLocalstorage() {
    if (isYoutubeEnabled()) {
        localStorage.removeItem('oebb-yt-videos');
    }
    else {
        localStorage.setItem('oebb-yt-videos', "true");
    }
}
exports.toggleYoutubeLocalstorage = toggleYoutubeLocalstorage;
function changeDisclaimerBtnText(disclaimerButton, lang) {
    if (!disclaimerButton)
        return;
    var i18n = new I18n_1.I18n(lang);
    if (isYoutubeEnabled()) {
        disclaimerButton.textContent = i18n.getProperty('video-deactivate', 'YouTube deaktivieren');
    }
    else {
        disclaimerButton.innerHTML = '<span class="ic-play-white"></span> ' +
            i18n.getProperty('video-activate', 'YouTube aktivieren');
    }
}
exports.changeDisclaimerBtnText = changeDisclaimerBtnText;
function createDisclaimerBtn() {
    var disclaimerBtn = document.createElement('button');
    disclaimerBtn.classList.add('js-disclaimer-btn');
    disclaimerBtn.classList.add('video__disclaimer-btn');
    disclaimerBtn.classList.add('button');
    disclaimerBtn.classList.add('button--primary');
    return disclaimerBtn;
}
exports.createDisclaimerBtn = createDisclaimerBtn;
function createDisclaimerTxt(lang) {
    var i18n = new I18n_1.I18n(lang);
    var disclaimer = document.createElement('p');
    disclaimer.classList.add('video__disclaimer-txt');
    disclaimer.innerHTML = i18n.getProperty('video-disclaimer', '');
    return disclaimer;
}
exports.createDisclaimerTxt = createDisclaimerTxt;
