"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initAccordions = void 0;
var TOGGLE_BUTTON_STATUS_ATTRIBUTE_NAME = "data-status";
var TOGGLE_BUTTON_STATUS_OPEN_ALL = "openAll";
var TOGGLE_BUTTON_STATUS_CLOSE_ALL = "closeAll";
function initAccordions() {
    var accordions = document.querySelectorAll(':not(.edit-mode) .accordeonContent');
    accordions.forEach(function (accordion, accordionIndex) {
        var toggleButton = undefined;
        if (accordion.querySelectorAll("li").length > 1) {
            toggleButton = addToggleButton(accordion);
        }
        accordion.querySelectorAll("li").forEach(function (accordionItem, itemIndex) {
            var handler = document.createElement("button");
            handler.setAttribute("aria-expanded", "false");
            var itemID = "accordionItem_".concat(accordionIndex, "_").concat(itemIndex);
            accordionItem.insertAdjacentElement("afterbegin", handler);
            var expandIcon = document.createElement("span");
            expandIcon.setAttribute("aria-hidden", "true");
            expandIcon.classList.add("ic-plus3");
            handler.appendChild(expandIcon);
            handler.setAttribute("aria-controls", itemID);
            handler.append(accordionItem.querySelectorAll("h1, h2, h3, h4, h5, h6")[0]);
            var accordionItemContent = accordionItem.querySelector("div");
            accordionItemContent.id = itemID;
            accordionItemContent.setAttribute("role", "region");
            accordionItemContent.setAttribute("aria-label", accordionItem.querySelectorAll("h1, h2, h3, h4, h5, h6")[0].textContent);
            accordionItemContent.setAttribute("tabindex", "-1");
            handler.onclick = function (event) {
                event.preventDefault();
                if (accordionItem.classList.contains("open")) {
                    handler.setAttribute("aria-expanded", "false");
                    accordionItem.classList.remove("open");
                }
                else {
                    handler.setAttribute("aria-expanded", "true");
                    accordionItem.classList.add("open");
                }
                if (toggleButton !== undefined && toggleButton !== null) {
                    updateToggleButton(toggleButton, accordion);
                }
            };
        });
    });
}
exports.initAccordions = initAccordions;
function addToggleButton(accordion) {
    var toggleButton = document.createElement("button");
    toggleButton.classList.add("accordeon__toggle-btn");
    toggleButton.setAttribute(TOGGLE_BUTTON_STATUS_ATTRIBUTE_NAME, TOGGLE_BUTTON_STATUS_OPEN_ALL);
    toggleButton.innerText = accordion.querySelector("ul").getAttribute("data-open-all");
    accordion.insertAdjacentElement("beforebegin", toggleButton);
    toggleButton.onclick = function (event) {
        event.preventDefault();
        if (toggleButton.getAttribute(TOGGLE_BUTTON_STATUS_ATTRIBUTE_NAME) === TOGGLE_BUTTON_STATUS_CLOSE_ALL) {
            toggleButton.setAttribute(TOGGLE_BUTTON_STATUS_ATTRIBUTE_NAME, TOGGLE_BUTTON_STATUS_OPEN_ALL);
            toggleButton.innerText = accordion.querySelector("ul").getAttribute("data-open-all");
            accordion.querySelectorAll("li").forEach(function (accordionElement) {
                accordionElement.classList.remove("open");
                // accordionElement.querySelector("a").setAttribute("aria-expanded", "false"); // TODO: fix
            });
        }
        else if (toggleButton.getAttribute(TOGGLE_BUTTON_STATUS_ATTRIBUTE_NAME) === TOGGLE_BUTTON_STATUS_OPEN_ALL) {
            toggleButton.setAttribute(TOGGLE_BUTTON_STATUS_ATTRIBUTE_NAME, TOGGLE_BUTTON_STATUS_CLOSE_ALL);
            toggleButton.innerText = accordion.querySelector("ul").getAttribute("data-close-all");
            accordion.querySelectorAll("li").forEach(function (accordionElement) {
                accordionElement.classList.add("open");
                // accordionElement.querySelector("a").setAttribute("aria-expanded", "true"); // TODO: fix
            });
        }
    };
    return toggleButton;
}
function updateToggleButton(toggleButton, accordion) {
    var allItems = accordion.querySelectorAll("li");
    var openItems = accordion.querySelectorAll("li.open");
    if (openItems.length < allItems.length) {
        toggleButton.setAttribute(TOGGLE_BUTTON_STATUS_ATTRIBUTE_NAME, TOGGLE_BUTTON_STATUS_OPEN_ALL);
        toggleButton.innerText = accordion.querySelector("ul").getAttribute("data-open-all");
    }
    else {
        toggleButton.setAttribute(TOGGLE_BUTTON_STATUS_ATTRIBUTE_NAME, TOGGLE_BUTTON_STATUS_CLOSE_ALL);
        toggleButton.innerText = accordion.querySelector("ul").getAttribute("data-close-all");
    }
}
