"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.I18n = void 0;
var I18n = /** @class */ (function () {
    function I18n(lang) {
        this.lang = lang;
        this.objects = [
            {
                key: "video-activate",
                de: "Youtube aktivieren",
                en: "Activate Youtube",
                it: "Attiva Youtube",
                nl: "Activeer Youtube",
                fr: "Activer Youtube",
            },
            {
                key: "video-deactivate",
                de: "Youtube deaktivieren",
                en: "Deactivate Youtube",
                it: "Disattiva Youtube",
                nl: "Deactiveer Youtube",
                fr: "Désactiver Youtube",
            },
            {
                key: 'video-disclaimer',
                de: 'Dieses Video ist im erweiterten Datenschutzmodus von Youtube eingebunden, der das Setzen von Youtube-Cookies solange blockiert, bis ein aktiver Klick auf die Wiedergabe erfolgt. Wir möchten Sie darüber informieren, dass wenn Sie auf den Wiedergabe-Button klicken, Youtube (Google) Cookies auf das von Ihnen verwendete Endgerät setzt, die auch zu einer Analyse des Nutzungsverhaltens zu Marktforschungs- und Marketing-Zwecken verwendet werden können (Third Party Cookies). Wir haben darauf keinen Einfluss. Von uns werden Daten weder an Youtube weitergeben noch erhalten wir von Youtube Daten aufgrund der vorgenannten Cookiesetzung. Näheres zur Cookie-Verwendung durch Youtube finden Sie in der Cookie-Policy von Google unter <a href="https://policies.google.com/technologies/types?hl=de" target="_blank">https://policies.google.com/technologies/types?hl=de</a>.',
                en: 'This video is integrated in YouTube\'s extended data protection mode, which blocks the setting of YouTube cookies until there is an active click on the playback. We would like to inform you that when you click on the play button, Youtube (Google) sets cookies on the device you are using, which can also be used to analyze usage behavior for market research and marketing purposes (third party cookies ). We have no influence on that. We neither pass on data to YouTube, nor do we receive data from YouTube due to the aforementioned cookie setting. You can find more information on the use of cookies by YouTube in Google\'s cookie policy at <a href="https://policies.google.com/technologies/types?hl=en" target="_blank">https://policies.google.com/technologies/types?hl=en</a>.',
                it: 'Questo video è integrato nella modalità di protezione dei dati estesa di YouTube, che blocca l\'impostazione dei cookie di YouTube finché non viene attivato un clic sulla riproduzione. Ti informiamo che quando fai clic sul pulsante di riproduzione, Youtube (Google) imposta dei cookie sul dispositivo che stai utilizzando, che possono essere utilizzati anche per analizzare il comportamento di utilizzo per ricerche di mercato e scopi di marketing (cookie di terze parti). Non abbiamo alcuna influenza su questo. Non trasmettiamo dati a YouTube, né riceviamo dati da YouTube a causa della suddetta impostazione dei cookie. Puoi trovare ulteriori informazioni sull\'uso dei cookie da parte di YouTube nella cookie policy di Google su <a href="https://policies.google.com/technologies/types?hl=it" target="_blank">https://policies.google.com/technologies/types?hl=it</a>.',
                nl: 'Deze video is geïntegreerd in de uitgebreide gegevensbeschermingsmodus van YouTube, die het plaatsen van YouTube-cookies blokkeert totdat er actief op het afspelen wordt geklikt. We willen u erop wijzen dat wanneer u op de afspeelknop klikt, YouTube (Google) cookies plaatst op het apparaat dat u gebruikt, die ook kunnen worden gebruikt om gebruiksgedrag te analyseren voor marktonderzoek en marketingdoeleinden (cookies van derden). Daar hebben wij geen invloed op. We geven geen gegevens door aan YouTube, noch ontvangen we gegevens van YouTube vanwege de bovengenoemde cookie-instelling. Meer informatie over het gebruik van cookies door YouTube vindt u in het cookiebeleid van Google op <a href="https://policies.google.com/technologies/types?hl=nl" target="_blank">https://policies.google.com/technologies/types?hl=nl</a>.',
                fr: 'Cette vidéo est intégrée au mode de protection des données étendu de YouTube, qui bloque le paramétrage des cookies YouTube jusqu\'à ce qu\'il y ait un clic actif sur la lecture. Nous tenons à vous informer que lorsque vous cliquez sur le bouton de lecture, Youtube (Google) installe des cookies sur l\'appareil que vous utilisez, qui peuvent également être utilisés pour analyser le comportement d\'utilisation à des fins d\'études de marché et de marketing (cookies tiers). Nous n\'avons aucune influence là-dessus. Nous ne transmettons pas de données à YouTube et ne recevons pas de données de YouTube en raison du paramètre de cookie susmentionné. Vous pouvez trouver plus d\'informations sur l\'utilisation des cookies par YouTube dans la politique de cookies de Google à l\'adresse <a href="https://policies.google.com/technologies/types?hl=fr" target="_blank">https://policies.google.com/technologies/types?hl=fr</a>.',
                bs: 'Ovaj video je integrisan u YouTube prošireni režim zaštite podataka, koji blokira podešavanje YouTube kolačića sve dok se aktivni klik ne reprodukuje. Klikom na dugme za reprodukciju dajete saglasnost da YouTube postavlja kolačiće na uređaj koji koristite, a koji se takođe može koristiti za analizu ponašanja prilikom korištenja u svrhu istraživanja tržišta i marketinške svrhe. Više informacija o upotrebi kolačića od strane YouTube možete pronaći u Google smjernicama za kolačiće na <a href="https://policies.google.com/technologies/types?hl=bs">https://policies.google.com/technologies/types?hl=bs</a>.',
                hu: 'A videót a Youtube megerősített adatvédelmi módjában ágyaztuk be, amely a Youtube-cookie-kat mindaddig blokkolja, amíg Ön el nem indítja a lejátszást. A lejátszás gombra kattintva beleegyezik abba, hogy a Youtube az Ön által használt eszközön cookie-kat tároljon el, amelyek a felhasználói viselkedés elemzése által piackutatási- és marketing célokra is felhasználhatóak. A Youtube cookie-használatáról további részleteket a Google Cookie-szabályzatában olvashat a <a href="https://policies.google.com/technologies/types?hl=hu">https://policies.google.com/technologies/types?hl=hu weboldalon</a>.',
                hr: 'Ovaj video je integriran u YouTubeovom proširenom načinu zaštite podataka, koji blokira postavljanje YouTube kolačića, sve dok se aktivno ne klikne na reprodukciju. Klikom na gumb za reprodukciju dajete svoj pristanak da YouTube postavi kolačiće na uređaj koji upotrebljavate, a koji se također mogu koristiti za analizu ponašanja korištenja u svrhu istraživanja tržišta i svrhe marketinga. Više informacija o upotrebi kolačića od strane YouTubea možete pronaći u Googleovim pravilima o kolačićima na <a href="https://policies.google.com/technologies/types?hl=hr">https://policies.google.com/technologies/types?hl=hr</a>.',
                ru: 'Данное видео включено в расширенный режим защиты данных Youtube, который блокирует сохранение файлов куки Youtube до момента, когда последует активный клик на воспроизведение. Нажатием кнопки воспроизведения вы даете согласие на то, что Youtube сохранит на используемом вами конечном устройстве файлы куки, которые также могут использоваться для анализа пользовательского поведения в целях маркетинговых исследований и маркетинга. Дополнительная информация об использовании Youtube файлов куки содержится в политике использования Гугл файлов куки по ссылке <a href="https://policies.google.com/technologies/types?hl=ru">https://policies.google.com/technologies/types?hl=ru</a>.',
                ro: 'Acest video este inclus în modul de protecţie a datelor, extins, de la Youtube, care blochează setarea cookie-urilor Youtube, până când are loc un clic activ pe redarea acestora. Dând clic pe butonul de redare, dumneavoastră vă exprimaţi acordul ca Youtube să seteze cookie-urile pe aparatul terminal utilizat de dumneavoastră, care pot să servească şi la analiza comportamentului de utilizare în scopuri de marketing şi de cercetare a pieţei. Informaţii detaliate privind utilizarea Cookie-urilor de către Youtube, le găsiţi în politica privind cookie-urile a companiei Google pe <a href="https://policies.google.com/technologies/types?hl=ro">https://policies.google.com/technologies/types?hl=ro</a>.',
                cs: 'Toto video je integrováno  v režimu rozšířené ochrany dat YouTube, který blokuje nastavení souborů cookies YouTube, dokud na přehrávání aktivně nekliknete. Kliknutím na tlačítko přehrát dáváte  YouTube  souhlas s umístěním souborů cookies na zařízení, které používáte. To  lze také použít k analýze chování uživatelů pro průzkum trhu a marketingové účely. Další informace o používání souborů cookies YouTube najdete v zásadách používání souborů cookies společnosti Google na adrese <a href="https://policies.google.com/technologies/types?hl=cs">https://policies.google.com/technologies/types?hl=cs</a>.',
                sl: 'Ta videoposnetek je integriran v YouTubov razširjeni način zaščite podatkov, ki blokira nastavitev piškotkov YouTube, dokler ne kliknete na gumb za predvajanje. S klikom na gumb za predvajanje podate svojo privolitev, da YouTube nastavi piškotke na napravi, ki jo uporabljate, s čimer se lahko analizira tudi vedenje uporabe za tržne raziskave in trženje. Več informacij o uporabi piškotkov v YouTubu najdete v Googlovem pravilniku o piškotkih na <a href="https://policies.google.com/technologies/cookies?hl=sl#types-of-cookies">https://policies.google.com/technologies/cookies?hl=sl#types-of-cookies</a>.',
            },
        ];
    }
    I18n.prototype.getLang = function () {
        return this.lang;
    };
    I18n.prototype.getProperty = function (name, defaultValue) {
        var result = this.objects.filter(function (element) {
            if (element.key == name)
                return true;
        });
        var output = defaultValue;
        if (result.length > 0) {
            if (result[0][this.lang]) {
                output = result[0][this.lang];
            }
        }
        return output;
    };
    return I18n;
}());
exports.I18n = I18n;
