"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initVideos = exports.Video = void 0;
var videoDisclaimer = __importStar(require("./VideoDisclaimer"));
var Video = /** @class */ (function () {
    function Video(element) {
        var _this = this;
        this.lang = document.documentElement.lang ? document.documentElement.lang : 'de';
        this.element = element;
        this.youTubeVideoId = element.dataset.context;
        if (this.element.classList.contains('js-video--preview')) {
            this.element.addEventListener('click', function (e) { return _this.handleClickNone(e); });
        }
        else {
            this.element.innerHTML = this.getMarkup(this.youTubeVideoId);
            this.initDisclaimerButtonEvents(this.element);
        }
    }
    Video.prototype.handleClickNone = function (e) {
        e.preventDefault();
        var parent = this.element.parentElement;
        var video = document.createElement('div');
        video.classList.add('js-video');
        video.dataset.context = this.youTubeVideoId;
        video.innerHTML = this.getMarkup(this.youTubeVideoId);
        parent.replaceChild(video, this.element);
        this.initDisclaimerButtonEvents(video);
    };
    Video.prototype.toggleAllVideos = function () {
        var allVideos = document.querySelectorAll('.js-video');
        if (allVideos) {
            for (var i = 0; i < allVideos.length; i++) {
                var currentVideo = allVideos.item(i);
                currentVideo.innerHTML = this.getMarkup(currentVideo.dataset.context);
                this.initDisclaimerButtonEvents(allVideos.item(i));
            }
        }
    };
    Video.prototype.getMarkup = function (youtubeVideoId) {
        var button = videoDisclaimer.createDisclaimerBtn();
        videoDisclaimer.changeDisclaimerBtnText(button, this.lang);
        if (videoDisclaimer.isYoutubeEnabled()) {
            return "\n            <div class=\"video-container\">\n                <iframe class=\"video-iframe\" src=\"https://www.youtube-nocookie.com/embed/".concat(youtubeVideoId, "?autoplay=0&fs=0&showinfo=0\" title=\"").concat('', "\" allow=\"autoplay; encrypted-media\" allowfullscreen=\"\"></iframe>\n            </div> ").concat(button.outerHTML, "\n        ");
        }
        else {
            return "<div class=\"js-video-disclaimer video-disclaimer\">".concat(videoDisclaimer.createDisclaimerTxt(this.lang).outerHTML, " ").concat(button.outerHTML, "</div>");
        }
    };
    Video.prototype.initDisclaimerButtonEvents = function (jsVideo) {
        var _this = this;
        var disclaimerDialogButton = jsVideo.querySelector('.js-disclaimer-btn');
        if (disclaimerDialogButton) {
            disclaimerDialogButton.addEventListener('click', function () {
                videoDisclaimer.toggleYoutubeLocalstorage();
                jsVideo.innerHTML = _this.getMarkup(jsVideo.dataset.context);
                _this.initDisclaimerButtonEvents(jsVideo);
                _this.toggleAllVideos();
            });
        }
    };
    return Video;
}());
exports.Video = Video;
function initVideos() {
    if (document.querySelector('.js-video')) {
        var videos = document.querySelectorAll('.js-video');
        for (var i = 0; i < videos.length; i++) {
            new Video(videos[i]);
        }
    }
}
exports.initVideos = initVideos;
